import React, { useState } from "react";
import styled from "styled-components";
import { StaticP } from "utility/ContentStyles.js";

const EmailForm = styled.form`
  text-align: center;
  margin: auto;
  padding-bottom: 24px;
`;
const EmailInputBox = styled.input`
  min-width: 250px;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.background};
  border-color: ${(props) => props.theme.border};
  border-width: 2px;
  border-style: solid;
  border-right: 0;
  height: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 16px;
`;
const EmailSubmitButton = styled.input`
  color: ${(props) => props.theme.textAlt};
  border-color: ${(props) => props.theme.border};
  border-width: 2px;
  border-style: solid;
  border-left: 0;
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  background-color: ${(props) => props.theme.backgroundAlt};
  width: 180px;
  height: 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.backgroundAltHover};
  }
`;

export default function MailingListAttendance() {
  const [address, setAddress] = useState("");
  const [result, setResult] = useState(null);

  // const [data, setData] = useState([]);
  // const [emailMap, setEmailMap] = useState({});

  async function getAttendancePoints(e) {


    e.preventDefault();
    setResult(null);

    try {
      const response = await fetch(
        "https://docs.google.com/spreadsheets/d/1xt3JR0K7toO4fof2zK3wFij8Re_-F5S4PksZohrckKM/pub?gid=0&single=true&output=csv"
      );
      const text = await response.text();
      const rows = text.split("\n").map((row) => row.split(","));
      // console.log(rows);
      // setData(rows);
      // console.log("DATA : ", data);

      // ------------------------------------------------
      // Create a hash map of email to value
      const emailHashMap = {};
      rows.forEach(row => {
        const email = row[0].trim(); // email
        const value = row[1] ? row[1].trim() : null; // point val
        if (email) {
          emailHashMap[email] = value;
        }
      });

      // setEmailMap(emailHashMap);
      // console.log("Address: ", address);
      // console.log("Email Map: ", emailMap);
      // console.log("Email HashMap: ", emailHashMap);

      if (emailHashMap[address]) {
        setResult(`Your Attendance Points: ${emailHashMap[address]}`);
      } else {
        // setResult("Email not found.");
        setResult(`Your Attendance Points: 0`);
      }
      // ------------------------------------------------

      setAddress("");
    } catch (error) {
      // console.error("Error fetching data", error);
      setResult("An error occurred while fetching data.");
    }
  }

  return (
    <EmailForm onSubmit={getAttendancePoints}>
      {result && <StaticP>{result}</StaticP>}
      <EmailInputBox
        type="email"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder="michiganhackers@umich.edu"
        required
      />
      <EmailSubmitButton type="submit" value="Check Attendance Points" />
    </EmailForm>
  );
}
