import React, { useState } from "react";
// import axios from "axios";
import styled from "styled-components";
import { StaticP } from "utility/ContentStyles.js";
// import { gmail } from "googleapis/build/src/apis/gmail";
// const fetch = require('node-fetch');
// const { Headers } = require('node-fetch'); // Import Headers from node-fetch


const EmailForm = styled.form`
  text-align: center;
  margin: auto;
  padding-bottom: 24px;
`;
const EmailInputBox = styled.input`
  min-width: 250px;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.background};
  border-color: ${(props) => props.theme.border};
  border-width: 2px;
  border-style: solid;
  border-right: 0;
  height: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 16px;
`;
const EmailSubmitButton = styled.input`
  color: ${(props) => props.theme.textAlt};
  border-color: ${(props) => props.theme.border};
  border-width: 2px;
  border-style: solid;
  border-left: 0;
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  background-color: ${(props) => props.theme.backgroundAlt};
  width: 60px;
  height: 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.backgroundAltHover};
  }
`;

/*
const addContactToMailjet = async () => {

// const axios = require('axios');

const data = JSON.stringify({
  // "Name": "John Smith",
  // "Properties": "object",
  "Action": "addnoforce",
  "Email": "ishanidas05@gmail.com"
});

const config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.mailjet.com/v3/REST/contactslist/1/managecontact',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic M2UxMWMyNmE1MTMxZjU4ZTYzYTJjMmQ3NWM2OWIwZjA6N2I3MTdkZDZmOTAxYmMyYTMwZmY4MTAwMTBlYmM2NjA='
  },
  data: data
};

axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });


};
*/
/*
const addContactToMailjet = async () => {

  // const MJ_APIKEY_PUBLIC = '3e11c26a5131f58e63a2c2d75c69b0f0';
  // const MJ_APIKEY_PRIVATE = '7b717dd6f901bc2a30ff810010ebc660';
  // const MJ_API_TOKEN = '1';

  const MJ_API_KEY = '3e11c26a5131f58e63a2c2d75c69b0f0';
  const MJ_SECRET_KEY = '7b717dd6f901bc2a30ff810010ebc660';
  const MJ_LIST_ID = '1';

  const url = `https://api.mailjet.com/v3/REST/contactslist/${MJ_LIST_ID}/managemanycontacts`;
  const authh = {
    username: MJ_API_KEY,
    password: MJ_SECRET_KEY,
  };

  const headers = {
    // 'Authorization': `Basic ${btoa(`${MJ_API_KEY}:${MJ_SECRET_KEY}`)}`,
    'Content-Type': 'application/json',
  };

  const body = {

    // Action: "addnoforce",
    // Email: "ishanidas05@gmail.com"
    Contacts: [{ Email: "ishanidas05@gmail.com", IsExcludedFromCampaigns: "false" }],
    ContactsLists: [{ Action: "noforce", ListID: "1" }],
  };

  try {
    const response = await axios.post(url, body, { headers, authh });
    console.log('Contact added successfully:', response.data);
  } catch (error) {
    console.error('Error adding contact:', error.response ? error.response.data : error.message);
  }
};
*/

export default function MailingList() {


  const [address, setAddress] = useState("");
  const [result, setResult] = useState(null);


  async function addEmailToList(e) {
    e.preventDefault();
    setResult(null);

    // addContactToMailjet();

    // console.log("need to add email: ", address);

    try {
      const response = await fetch('https://mh-web-new-backend-4790357c4eea.herokuapp.com/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: address }),
      });

      if (response.ok) {
        setResult('You\'ve have been added to the email list!');
      } else {
        setResult('You are already on the mailing list or we\'re having some issues adding you. Please contact michiganhackers-exec@umich.edu to confirm.');
      }
    } catch (error) {
      console.error('Error adding contact:', error);
      //  setResult('Error adding contact.');
      setResult('You are already on the mailing list or we\'re having some issues adding you. Please contact michiganhackers-exec@umich.edu to confirm.');
    }
  }

  return (
    <EmailForm onSubmit={addEmailToList}>
      {result && <StaticP>{result}</StaticP>}
      <EmailInputBox
        type="email"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder="michiganhackers@umich.edu"
        required
      />
      <EmailSubmitButton type="submit" value="Join" />
    </EmailForm>
  );
}
